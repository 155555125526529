<template>
  <div>

    <v-dialog
      v-model="dialog_sample"
      width="650px"
      overlay-color="transparent"
      :eager="true"
    >

      <v-card max-height="100vh" class="position-relative scroll">

        <v-toolbar large flat color="transparent">
          <v-toolbar-title style="max-width:150px;">
            <v-icon class="mr-2" size="20">mdi-playlist-check</v-icon> {{$t('COMMON.SAMPLE')}}
          </v-toolbar-title>
          <v-spacer />
          <div class="mr-6">
            <v-btn-toggle v-model="example_format" mandatory>
              <v-btn small depressed value="input" title="Text w/o change">FILE</v-btn>
              <v-btn small depressed value="output" title="Text with changes">TEXT</v-btn>
              <v-btn small depressed class="mr-2" value="output_html" title="Html with changes">HTML</v-btn>
            </v-btn-toggle>
          </div>
          <div class="width-30 mr-6">
            <v-select dense v-model="tab" :items="examples.map((v,i) => ({text:$t('COMMON.SAMPLE') + ' ' + (i+1), value:i}))" outlined hide-details :placeholder="$t('COMMON.SAMPLE')" />
          </div>

          <v-btn fab small depressed @click="dialog_sample = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-divider />

        <div class="scroll yellow lighten-4" style="height:70vh; max-height:70vh;">


            <v-tabs-items v-model="tab">
              <v-tab-item
              transition="none"
              v-for="(ex,j) in examples"
              :key="'tabexx'+j"
              class="yellow lighten-4 pb-10"
              >
                <template v-for="(item, i) in fileformat_hooks_base">
                  <div :key="'tabexx'+j+i" v-if="fileformat_hooks_form[i] && !item.separator && !item.separator_contact && !item.separator_company && !item.separator_advanced">
                   <div class="pl-10 muted"><small>{{$t('INPUT.' + fileformat_hooks_form[i].key.toUpperCase())}}</small></div>
                   <v-divider />
                   <div class="pr-10 pl-10 pa-4" style="opacity:0.7; font-size:80%; font-family: mono;" v-if="example_format === 'input'" >
                     <i v-text="exampleText(fileformat_hooks_form[i], ex)" />
                   </div>
                   <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:80%; font-family: mono;" v-if="example_format === 'output'" >
                     <div v-text="applyChanges(exampleText(fileformat_hooks_form[i], ex), fileformat_hooks_form[i].changes)" />
                   </div>
                   <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:90%;" v-if="example_format === 'output_html'">
                     <div v-html="toHtml(applyChanges(exampleText(fileformat_hooks_form[i], ex), fileformat_hooks_form[i].changes))" />
                   </div>
                 </div>
               </template>
               <v-divider />
               <div class="pa-4 pl-10 white"><small>{{$t('FEED.HOOK_RESULTS')}}: <strong>{{duplicated_values.length}}</strong> <span class="muted">[{{scopes.join(', ')}}]</span></small></div>
               <v-divider />
               <div class="pl-10" v-for="(city, i) in duplicated_values" :key="'cc'+i">
                 <small class="muted">{{i}}. {{city.location}}</small>
               </div>
             </v-tab-item>
           </v-tabs-items>

      </div>

      </v-card>
    </v-dialog>




    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-form ref="form_hooks" v-model="valid">
        <v-card max-height="100vh">

          <v-overlay color="white" :opacity="0.8" absolute :value="!dialog_advanced.every(v => v === false)" />

          <div>
            <v-system-bar dark color="primary">
              <v-icon class="mr-1">mdi-pencil</v-icon>
              <small><strong class="text-uppercase">{{$t('COMMON.MODIFY')}}</strong></small>
              <v-spacer/>
              <v-icon
                small
                class="clickable"
                @click="closeDialog"
              >
                mdi-close
              </v-icon>
            </v-system-bar>

            <v-toolbar
              color="transparent"
              dense
              flat
              height="60px"
            >
              <v-toolbar-title>{{$t('FEED.FILEFORMAT_HOOKS_TITLE')}}</v-toolbar-title>
              <v-spacer />
              <v-btn
                small
                depressed
                rounded
                class="mr-4"
                @click="dialog_sample = true"
                >
                <v-icon class="mr-2">mdi-playlist-check</v-icon>
                <span class="mr-2">{{$t('COMMON.SAMPLE')}}</span>
                <v-chip x-small color="info">{{examples.length}}</v-chip>
              </v-btn>
              <v-btn
                x-small
                fab
                depressed
                color="warning"
                class="mr-4"
                @click="closeDialog"
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                rounded
                fab
                small
                depressed
                color="success"
                :disabled="!valid"
                @click="apply"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-toolbar>

            <div class="toolbar-separator primary ml-4 mr-4"/>
          </div>

          <div class="pa-6 pt-0 scroll" style="max-height: calc(80vh - 61px)" ref="content">
            <v-row class="grey--text mt-4 text-small text-uppercase">
              <v-col cols="3">{{$t('FEED.HEADER_KEY_NAME')}}</v-col>
              <v-col cols="9">{{$t('FEED.HEADER_COLUMNS')}}</v-col>
            </v-row>
            <template v-for="(item, i) in fileformat_hooks_base">
              <div v-if="fileformat_hooks_form[i]" :key="'fhb'+i">
                <v-row
                  class="mt-0"
                  v-if="!item.custom"
                >
                  <v-col cols="3" class="pr-0">
                    <div v-if="!item.custom" class="pt-2">
                      {{$t('INPUT.' + fileformat_hooks_form[i].key.toUpperCase())}}
                      <span class="red--text" v-if="item.required">*</span>
                    </div>
                    <v-text-field
                      v-else
                      dense
                      hide-details
                      outlined
                      v-model="fileformat_hooks_form[i].key"
                    />
                  </v-col>
                  <v-col cols="7">
                    <v-combobox
                      small-chips
                      clearable
                      multiple
                      dense
                      hide-details
                      :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                      :items="columns"
                      v-model="fileformat_hooks_form[i].value"
                      outlined
                      required
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            {{ item }}
                          </v-list-item-content>
                          <v-spacer></v-spacer>
                          <v-list-item-action>
                            <v-menu max-width="400px" max-height="500px" open-on-hover>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon>
                                  <v-icon>mdi-message-question-outline</v-icon>
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-subtitle
                                  >{{ $t("FEED.FIELD_FILEFORMAT_EXAMPLES") }} ({{
                                    item
                                  }})</v-card-subtitle
                                >
                                <v-card-text
                                  v-for="(example, index) in itemExamples(item)"
                                  :key="`ex${index}`"
                                  class="pt-0"
                                >
                                  " {{ example }} "
                                  <v-divider class="mt-1" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-list-item-action>
                        </template>
                      </v-combobox>
                  </v-col>

                  <v-col cols="2" class="pr-0 pl-0 text-right position-relative" :key="updateKey">
                    <v-badge
                      bordered
                      color="info"
                      overlap
                      icon="mdi-exclamation-thick"
                      left
                      :value="hasSettings(i)"
                      >
                        <v-btn
                          icon
                          @click="showAdvancedDialog(i)"
                        >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </v-badge>

                    <v-dialog
                      :value="dialog_advanced[i]"
                      persistent
                      overlay-color="transparent"
                      :eager="true"
                      max-width="650px"
                      min-width="650px"
                    >

                      <v-card
                        width="650px"
                        max-height="75vh"
                        class="scroll"
                        :ref="'menu_changes_'+i"
                      >
                        <v-toolbar large flat color="transparent">
                          <v-toolbar-title style="max-width:150px;">
                            <v-icon class="mr-2" size="20">mdi-cog</v-icon>{{$t('INPUT.' + fileformat_hooks_form[i].key.toUpperCase())}}
                          </v-toolbar-title>
                          <v-spacer />
                          <div class="mr-6">
                            <v-btn-toggle v-model="example_format" mandatory>
                              <v-btn small depressed value="input" title="Text w/o change">FILE</v-btn>
                              <v-btn small depressed value="output" title="Text with changes">TEXT</v-btn>
                              <v-btn small depressed class="mr-2" value="output_html" title="Html with changes">HTML</v-btn>
                            </v-btn-toggle>
                          </div>
                          <div class="width-30 mr-6">
                            <v-select dense v-model="tab" :items="examples.map((v,i) => ({text:$t('COMMON.SAMPLE') + ' ' + (i+1), value:i}))" outlined hide-details :placeholder="$t('COMMON.SAMPLE')" />
                          </div>

                          <v-btn fab small color="success" depressed @click="closeAdvancedDialog(i)"><v-icon>mdi-check</v-icon></v-btn>
                        </v-toolbar>
                        <v-divider />

                        <div class="scroll yellow lighten-4" style="height:20vh; max-height:20vh;">
                          <div :key="exampleKey" >
                            <v-tabs-items v-model="tab">
                              <v-tab-item
                              transition="none"
                              v-for="(ex,j) in examples"
                              :key="'tabexx'+i+j"
                              class="yellow lighten-4"
                              >

                                <div class="pr-10 pl-10 pa-4" style="opacity:0.7; font-size:80%; font-family: mono;" v-if="example_format === 'input'" >
                                  <i v-text="exampleText(fileformat_hooks_form[i], ex)" />
                                </div>
                                <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:80%; font-family: mono;" v-if="example_format === 'output'" >
                                  <div v-text="applyChanges(exampleText(fileformat_hooks_form[i], ex), fileformat_hooks_form[i].changes)" />
                                </div>
                                <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:90%;" v-if="example_format === 'output_html'">
                                  <div v-html="toHtml(applyChanges(exampleText(fileformat_hooks_form[i], ex), fileformat_hooks_form[i].changes))" />
                                </div>

                              </v-tab-item>
                            </v-tabs-items>
                          </div>
                        </div>
                        <v-divider />

                        <v-tabs dense height="35px" v-model="tab2" background-color="secondary">
                          <v-tab>
                            {{$t('INPUT.VALUES')}}
                          </v-tab>
                          <v-tab>
                            {{$t('FEED.FIELD_FILEFORMAT_CHANGES')}}
                          </v-tab>
                          <v-spacer />
                        </v-tabs>
                        <div class="scroll" style="height:30vh; max-height:30vh;">
                          <v-tabs-items v-model="tab2">

                            <v-tab-item transition="none" class="pa-6">
                              <v-row class="mt-0 mb-0">
                                <v-col cols="3" class="pr-0">
                                  <div v-if="!item.custom" class="pt-2">
                                    {{$t('INPUT.VALUES')}}
                                  </div>
                                </v-col>
                                <v-col cols="9">
                                  <v-combobox
                                  small-chips
                                  clearable
                                  multiple
                                  dense
                                  hide-details
                                  :rules="isValidFields(i) || !item.required ? [true] : [$rules.required]"
                                  :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                                  :items="columns"
                                  v-model="fileformat_hooks_form[i].value"
                                  outlined
                                  >
                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        {{ item }}
                                      </v-list-item-content>
                                      <v-spacer></v-spacer>
                                      <v-list-item-action>
                                        <v-menu max-width="400px" max-height="500px" open-on-hover>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon>
                                              <v-icon>mdi-message-question-outline</v-icon>
                                            </v-btn>
                                          </template>
                                          <v-card>
                                            <v-card-subtitle
                                            >{{ $t("FEED.FIELD_FILEFORMAT_EXAMPLES") }} ({{
                                              item
                                            }})</v-card-subtitle
                                            >
                                            <v-card-text
                                            v-for="(example, index) in itemExamples(item)"
                                            :key="`ex${index}`"
                                            class="pt-0"
                                            >
                                            " {{ example }} "
                                            <v-divider class="mt-1" />
                                          </v-card-text>
                                        </v-card>
                                      </v-menu>
                                    </v-list-item-action>
                                    </template>
                                  </v-combobox>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 mb-0">
                                <v-col cols="3" class="pt-0">
                                  <div class="pt-2 text-nowrap text-ellipsis">
                                    {{$t('FEED.FIELD_FILEFORMAT_DEFAULT_VALUE')}}
                                  </div>
                                </v-col>
                                <v-col cols="9" class="pt-0">
                                  <v-text-field
                                  dense
                                  hide-details
                                  :placeholder="$t('FEED.FIELD_FILEFORMAT_DEFAULT_VALUE')"
                                  v-model="fileformat_hooks_form[i].default_value"
                                  outlined
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 mb-0">
                                <v-col cols="3" class="pt-0">
                                  <div class="pt-2 text-nowrap text-ellipsis">
                                    {{$t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE')}}
                                  </div>
                                </v-col>
                                <v-col cols="9" class="pt-0">
                                  <v-autocomplete
                                  v-model="fileformat_hooks_form[i].excludes"
                                  :items="fileformat_hooks_form[i].excludes"
                                  :label="$t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE')"
                                  :placeholder="$t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE')"
                                  clearable
                                  outlined
                                  dense
                                  hide-details
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 mb-0">
                                <v-col cols="3" class="pt-0">
                                  <div class="pt-2 text-nowrap text-ellipsis">
                                    {{$t('FEED.FIELD_FILEFORMAT_SPACING')}}
                                  </div>
                                </v-col>
                                <v-col cols="3" class="pt-0">
                                  <v-text-field
                                  v-model="fileformat_hooks_form[i].spacing"
                                  :label="$t('FEED.FIELD_FILEFORMAT_SPACING')"
                                  :placeholder="$t('FEED.FIELD_FILEFORMAT_SPACING')"
                                  clearable
                                  outlined
                                  dense
                                  hide-details
                                  />
                                </v-col>
                              </v-row>
                              <v-row class="mt-0 mb-0">
                                <v-col cols="3" class="pt-0 pb-0"/>
                                <v-col cols="9" class="pt-0 pb-0">
                                  <v-checkbox
                                    v-model="fileformat_hooks_form[i].force"
                                    :label="$t('FEED.FIELD_FILEFORMAT_FORCE')"
                                    :placeholder="$t('FEED.FIELD_FILEFORMAT_FORCE')"
                                    outlined
                                    class="mt-0 pt-0 mb-0"
                                  />
                                </v-col>
                              </v-row>
                            </v-tab-item>

                            <v-tab-item transition="none">
                              <v-row class="ma-0">
                                <!-- <v-col cols="3" class="pt-0">
                                  <div class="pt-2 text-nowrap text-ellipsis">
                                    {{$t('FEED.FIELD_FILEFORMAT_CHANGES')}}
                                  </div>
                                </v-col> -->
                                <v-col cols="12" class="pa-4">
                                  <v-btn
                                    small
                                    depressed
                                    rounded
                                    color="secondary black--text"
                                    @click="addChanges(i)"
                                  >
                                    <v-icon class="mr-2">mdi-plus</v-icon>
                                    {{$t('FEED.FIELD_FILEFORMAT_CHANGES_BTN')}}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <template v-for="(change, j) in fileformat_hooks_form[i].changes">
                                <v-divider :key="'d'+j" class="mb-3" />
                                <v-row :key="'ch'+j" class="mt-0 mb-0 mr-4">
                                  <v-col cols="3" class="pt-0 text-right">
                                    <v-chip style="width:32px; justify-content: center;" class="mr-4">{{j+1}}</v-chip>
                                    <v-btn
                                      :disabled="!changesCanUp(i,j)"
                                      @click="uppperChanges(i,j)"
                                      icon
                                      class="ml-2"
                                      small
                                    >
                                      <v-icon size="18">mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                      :disabled="!changesCanLow(i,j)"
                                      @click="lowerChanges(i,j)"
                                      icon
                                      class="ml-2"
                                      small
                                    >
                                      <v-icon size="18">mdi-arrow-down</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="3" class="pt-0">
                                    <v-select
                                      v-model="fileformat_hooks_form[i].changes[j].name"
                                      :items="Object.keys(change_functions)"
                                      :label="$t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION')"
                                      :rules="[$rules.required]"
                                      required
                                      hide-details
                                      outlined
                                      dense
                                      @change="
                                        name => fileformat_hooks_form[i].changes[j].args= new Array(change_functions[name].args.length).fill(null)
                                        && updateChangesRender()
                                      "
                                    />
                                  </v-col>
                                  <v-col cols="6" class="pt-0 text-right pr-0">
                                    <v-btn
                                      icon
                                      @click="removeChanges(i,j)"
                                      class="mr-2"
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="3"/>
                                  <v-col
                                    cols="5"
                                    class="pt-0 pr-1"
                                    v-if="change_functions[fileformat_hooks_form[i].changes[j].name].args[0]"
                                  >
                                    <v-text-field
                                      v-model="fileformat_hooks_form[i].changes[j].args[0]"
                                      :label="
                                        $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                        + change_functions[fileformat_hooks_form[i].changes[j].name].args[0].toUpperCase())
                                      "
                                      :placeholder="
                                        $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                        + change_functions[fileformat_hooks_form[i].changes[j].name].args[0].toUpperCase())
                                      "
                                      hide-details
                                      clearable
                                      outlined
                                      dense
                                      @change="updateChangesRender"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="4"
                                    class="pl-0 pt-0"
                                    v-if="change_functions[fileformat_hooks_form[i].changes[j].name].args[1]"
                                  >
                                    <v-text-field
                                      v-model="fileformat_hooks_form[i].changes[j].args[1]"
                                      :label="
                                        $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                        + change_functions[fileformat_hooks_form[i].changes[j].name].args[1].toUpperCase())
                                      "
                                      :placeholder="
                                        $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                        + change_functions[fileformat_hooks_form[i].changes[j].name].args[1].toUpperCase())
                                      "
                                      hide-details
                                      clearable
                                      outlined
                                      dense
                                      @change="updateChangesRender"
                                    />
                                  </v-col>
                                </v-row>
                              </template>
                            </v-tab-item>
                          </v-tabs-items>
                        </div>

                      </v-card>
                    </v-dialog>
                  </v-col>

                </v-row>
              </div>
            </template>
          </div>

        </v-card>
      </v-form>
    </v-dialog>

    <div>
      <span class="primary--text">{{$t('FEED.FILEFORMAT_HOOKS_TITLE')}}</span>
      <v-text-field
        name="form_hooks"
        style="display:none;"
        :value="valid"
        :rules="[$rules.required]"
      />
      <v-btn
        small
        rounded
        depressed
        color="secondary black--text"
        class="ml-4 mr-4"
        @click="showDialog"
        :disabled="!values.fileformat_columns || values.fileformat_columns.length === 0"
      >
        {{$t('COMMON.MODIFY')}}
      </v-btn>
      <span
        class="grey--text text-medium"
        v-if="values.fileformat_columns && values.fileformat_columns.length > 0"
      >
        {{$tc('FEED.FILEFORMAT_HOOKS_COUNT', filledFieldsCount)}}
      </span>
    </div>

    <div class="mt-4">
      <template v-for="(field, i) in fileformat_hooks">
        <div v-if="(field.value && field.value.length > 0) || field.default_value" :key="'fir'+i">
          <v-divider />
          <v-row class="mt-0 mb-0 text-medium">
            <v-col
              cols="2"
              class="text-ellipsis text-nowrap grey--text"
              :title="$t('INPUT.' + field.key.toUpperCase())"
            >
              {{$t('INPUT.' + field.key.toUpperCase())}}
            </v-col>
            <v-col cols="10" class="text-ellipsis text-nowrap">
              <span>{{(field.value || []).join(', ') || field.default_value}}</span>
              <v-icon
                class="ml-2"
                size="12"
                v-if="
                  (field.changes && field.changes.length > 0)
                  || field.spacing
                  || (field.excludes && field.excludes.length > 0)
                "
              >
                mdi-cog
              </v-icon>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>

  </div>
</template>

<script>

const default_fields = [
  {key: 'duplicate_by_cities_in_country', value: [], default_value: undefined, separator: undefined, spacing: undefined, changes: [], excludes: [], force: false, params: {} },
  {key: 'duplicate_by_cities_in_region', value: [], default_value: undefined, separator: undefined, spacing: undefined, changes: [], excludes: [], force: false, params: {} },
  {key: 'duplicate_by_cities_in_department', value: [], default_value: undefined, separator: undefined, spacing: undefined, changes: [], excludes: [], force: false, params: {} },
  {key: 'duplicate_by_cities_in_description', value: [], default_value: undefined, separator: undefined, spacing: undefined, changes: [], excludes: [], force: false, params: {} },
]

const _change_functions = {
  'extract': {name: 'extract', args:['value']},
  'replace': {name: 'replace', args:['value', 'replace']},
  'prepend': {name: 'prepend', args:['value']},
  'append': {name: 'append', args:['value']},
}

export default {
  name:"FormFeedCompactHooks",
  data: (_this) => ({
    valid: false,
    change_functions: _this.$clone(_change_functions),
    custom_field: {key: '', value: [], default_value: undefined, separator: undefined, spacing: undefined, changes: [], excludes: [], custom: true},
    selectedSettings: -1,
    updateKey: 0,
    dialog: false,
    dialog_advanced: new Array(100).fill(0).map(v => false),
    dialog_sample: false,
    fileformat_hooks_base: _this.$clone(default_fields),
    fileformat_hooks_form: _this.$clone(default_fields),
    tab: 0,
    tab2: 0,
    //
    fileformat: undefined,
    fileformat_list: undefined,
    fileformat_hooks: _this.$clone(default_fields),
    fileformat_columns: [],
    duplicated_values: new Array(10).fill(0).map(v => ([])),
    examples: [],
    example_format: 'output',
    exampleKey:0,
    count: 0,
    scopes: [],
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  computed: {
    columns () {
      return this.values.fileformat_columns || []
    },
    filledFieldsCount () {
      return this.fileformat_hooks.filter(v => (v.value && v.value.length) || v.default_value).length
    },
    filledFieldsFormCount () {
      return this.fileformat_hooks_form.filter(v => (v.value && v.value.length) || v.default_value).length
    }
  },
  watch: {
    tab (n) {
      if (this.dialog_sample) this.simulateDuplication()
    },
    dialog_sample (n) {
      if (n) this.simulateDuplication()
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {

    // simulate showndown html cleaning
    toHtml (str = '') {
      str = this.$showdown.makeMarkdown(str)
      str = this.$showdown.makeHtml(str)
      return str
    },

    applyChanges(value = '', changes = []) {
      // console.log("applyChanges") //value, changes)
      for (let j = 0, len = changes.length; j < len; j++) {
        const change = changes[j]
        if (change.name && change.args && change.args.length) switch (change.name) {
          case 'extract': {
            const reg = new RegExp(change.args[0], 'gim')
            const m = reg.exec(value)
            value = ''
            if (m && m.length > 1) {
              value = m.slice(1).map(v => (v || '').trim()).filter(v => v || v === 0).join(', ')
            }
            break
          }
          case 'replace': {
            value = value.replace(new RegExp(change.args[0], 'gmi'), (change.args[1] || ''))
            break
          }
          case 'prepend': {
            value = (change.args[0] || '') + value
            break
          }
          case 'append': {
            value = value + (change.args[0] || '')
            break
          }
        }
      }
      return value
    },

    exampleText (item, example) {
      const getChildValue = (v) => {
        if (v && v['_']) return v['_']
        return v
      }
      let values = item.value || []
      if (!(values instanceof Array)) values = [values]
      values = values.map(key => {
        if (key) {
          let value = this.$nestedData(key, example, '', true)
          if (value && item.separator && value.includes(item.separator)) {
            value = value.split(item.separator)
              .filter(v => v || v === 0)
              .join(item.spacing || ', ')
            value = value ? value.trim() : ''
          }
          if (value && item.excludes.indexOf(value) === -1) {
            return getChildValue(value)
          }
        }
      })
      values = values.filter(v => v || v === 0)
      values = values.join(item.spacing || ', ')
      values = (values ? values.trim() : item.default_value) || ''
      return values
    },

    showAdvancedDialog (i) {
      this.dialog_advanced[i] = true
      this.updateKey++
    },

    closeAdvancedDialog (i) {
      this.dialog_advanced[i] = false
      this.updateKey++
    },

    itemExamples(field) {
      const getChildValue = (v) => {
        if (v && v['_']) return v['_']
        return v
      }
      const examples = this.values.examples
      let result = [];
      for (let i = 0; i < examples.length; i++) {
        console.log(i, field, this.$nestedData(field, examples[i], '', true))
        result.push(getChildValue(this.$nestedData(field, examples[i], '', true)))
      }
      return result
      // Object.entries(examples[i]).forEach((exampleArray) => {
      //   const [key, value] = exampleArray
      //   console.log(i, exampleArray, key === field)
      //   if (key === field) {
      //     let newValue = value
      //     if (value instanceof Array && value.length === 1) {
      //       newValue = newValue[0]
      //     }
      //     if (typeof value === 'object' && value._ ) {
      //       result.push(newValue._)
      //     } else {
      //       result.push(newValue)
      //     }
      //   }
      // })
    },

    isValidFields (i) {
      return (
        (this.fileformat_hooks_form[i].value && this.fileformat_hooks_form[i].value.length > 0)
        || this.fileformat_hooks_form[i].default_value
      )
    },

    hasSettings (i) {
      return this.fileformat_hooks_form[i].default_value
        || this.fileformat_hooks_form[i].force
        || this.fileformat_hooks_form[i].spacing
        || this.fileformat_hooks_form[i].changes.length > 0
        || this.fileformat_hooks_form[i].excludes.length > 0
    },

    // form fields
    apply () {
      if (this.$refs.form_hooks.validate()) {
        this.fileformat_hooks = this.$clone(this.fileformat_hooks_form.filter(v => !v.separator))
        this.fileformat_fields_form = this.$clone(default_fields)
        this.$emit('input', {fileformat_hooks: this.fileformat_hooks})
        this.dialog = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    showDialog () {
      this.fileformat_hooks_form = this.$clone(this.fileformat_hooks)
      this.dialog = true
    },

    updateChangesRender () {
      this.exampleKey++
    },


    // changes in fields
    removeChanges (i,j) {
      this.fileformat_hooks_form[i].changes.splice(j,1)
      // // this.$refs[`menu_changes_${i}`][0].onResize()
      this.updateChangesRender()
    },
    addChanges (i) {
      this.fileformat_hooks_form[i].changes.push({name: 'replace', args:[]})
      // this.$refs[`menu_changes_${i}`][0].onResize()
      this.updateChangesRender()
    },
    uppperChanges (i,j) {
      if (this.changesCanUp(i,j)) {
        const v = this.fileformat_hooks_form[i].changes[j]
        this.fileformat_hooks_form[i].changes.splice(j, 1)
        this.fileformat_hooks_form[i].changes.splice(j-1, 0, v)
        this.updateChangesRender()
      }
    },
    lowerChanges (i,j) {
      if (this.changesCanLow(i,j)) {
        const v = this.fileformat_hooks_form[i].changes[j]
        this.fileformat_hooks_form[i].changes.splice(j, 1)
        this.fileformat_hooks_form[i].changes.splice(j+1, 0, v)
        this.updateChangesRender()
      }
    },
    changesCanUp (i,j) {
      return j > 0
    },
    changesCanLow (i,j) {
      const len = this.fileformat_hooks_form[i].changes.length
      return j < len-1
    },


    // field settings
    showSetting(i) {
      if (this.selectedSettings === i) {
        this.selectedSettings = -1
      } else {
        this.selectedSettings = i
      }
    },


    // merge fields values
    mergeFileformatFields (values, key) {
      const data = []
      if (values[key] && (values[key] instanceof Array) && this[key] && (this[key] instanceof Array)) {
        for (let i = 0, len1 = values[key].length; i < len1; i++) {
          const field1 = values[key][i]
          for (let j = 0, len2 = this[key].length; j < len2; j++) {
            const field2 = this[key][j]
            if (field1.key === field2.key) {
              this[key][j] = this.$clone(field1)
              break
            } else if (j === len2-1) {
              this[key].push(this.$clone(field1))
            }
          }
        }
      }
    },


    // attrib values in data
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'fileformat_hooks') {
          this.mergeFileformatFields(values, key)
        } else {
          this[key] = values[key]
        }
      })
      this.updateChangesRender()
    },


    // Duplication simulation

    async simulateDuplication () {
      // console.log('duplication on example:', this.tab)
      let addToScope = false
      this.duplicated_values = []
      const splitAndCleanLocation = str => str.replace(/([^-\wÀ-ÖØ-öø-ÿ\s'’]+|-{2,}| et | ou | and | or |([Ss]ecteur(s)?(\s|de|des|du)*))+/gmi, ', ').split(',').map(v => (v || '').trim()).filter(v => v)
      const duplicated_values = []
      const uniq_ids = []
      let i = -1
      const _hooks = this.$clone(this.fileformat_hooks_form)
      _hooks.sort((h1, h2) => {
        const order = ['duplicate_by_cities_in_description', 'duplicate_by_cities_in_department', 'duplicate_by_cities_in_region', 'duplicate_by_cities_in_country']
        const p1 = order.indexOf(h1.key) === -1 ? order.length : order.indexOf(h1.key)
        const p2 = order.indexOf(h2.key) === -1 ? order.length : order.indexOf(h2.key)
        return p1 - p2
      })

      for (const _hook of _hooks) {
        ++i
        const hook = this.$clone(_hook)
        const key = hook.key
        hook.value = this.exampleText(hook, this.examples[this.tab])
        // console.log(hook.key, '=', hook.value)
        if (hook.value || hook.default_value) switch (key) {

          // find cities in string: city1, city2, city3 ...
          case 'duplicate_by_cities_in_description':
          addToScope = false
          if (duplicated_values.length === 0 || hook.force) {
            const cities = splitAndCleanLocation(hook.value || hook.default_value)
            for (let j = 0, len = cities.length; j < len; j++) {
              const city = cities[j]
              const locations = await this.$services.api_programmatic.location.search({where:{ city: city }, options: { limit: 1 }})
              for (let j = 0, len = locations.length; j < len; j++) {
                const location = locations[j]
                if (uniq_ids.indexOf(location.location_insee_code) === -1) {
                  uniq_ids.push(location.location_insee_code)
                  duplicated_values.push(location)
                  addToScope = true
                }
              }
              if (addToScope) this.scopes.push('description')
            }
          }
          break

          // find major cities in a country
          case 'duplicate_by_cities_in_country':
          if (duplicated_values.length === 0 || hook.force) {
            addToScope = false
            const countries = splitAndCleanLocation(hook.value || hook.default_value)
            for (let j = 0, len = countries.length; j < len; j++) {
              const country = countries[j]
              const {data: locations} = await this.$services.api_programmatic.location.search({where:{ country: country, level: 9 },options: {limit: 1000}})
              // console.log('duplication contry', locations)
              for (let j = 0, len = locations.length; j < len; j++) {
                const location = locations[j]
                if (uniq_ids.indexOf(location.location_insee_code) === -1) {
                  uniq_ids.push(location.location_insee_code)
                  duplicated_values.push(location)
                  addToScope = true
                }
              }
            }
            if (addToScope) this.scopes.push('country')
          }
          break

          // find major cities in a region
          case 'duplicate_by_cities_in_region':
          if (duplicated_values.length === 0 || hook.force) {
            addToScope = false
            const regions = splitAndCleanLocation(hook.value || hook.default_value)
            for (let j = 0, len = regions.length; j < len; j++) {
              const region = regions[j]
              const {data: locations} = await this.$services.api_programmatic.location.search({where:{ region: region, level: [9, 8, 7] },options: {limit: 1000}})
              for (let j = 0, len = locations.length; j < len; j++) {
                const location = locations[j]
                if (uniq_ids.indexOf(location.location_insee_code) === -1) {
                  uniq_ids.push(location.location_insee_code)
                  duplicated_values.push(location)
                  addToScope = true
                }
              }
            }
            if (addToScope) this.scopes.push('region')
          }
          break

          // find major cities in a department
          case 'duplicate_by_cities_in_department':
          if (duplicated_values.length === 0 || hook.force) {
            addToScope = false
            const departments = splitAndCleanLocation(hook.value || hook.default_value)
            for (let j = 0, len = departments.length; j < len; j++) {
              const department = departments[j]
              const {data: locations} = await this.$services.api_programmatic.location.search({where:{ department: department, level: [9, 8, 7] }, options: {limit: 1000}})
              // console.log('duplication department', locations)
              for (let j = 0, len = locations.length; j < len; j++) {
                const location = locations[j]
                if (uniq_ids.indexOf(location.location_insee_code) === -1) {
                  uniq_ids.push(location.location_insee_code)
                  duplicated_values.push(location)
                  addToScope = true
                  // console.log('duplicated_values department')
                }
              }
            }
            if (addToScope) this.scopes.push('department')
          }
          break

        }

      }
      this.duplicated_values = this.$clone(duplicated_values)

    }




  }
}
</script>
